import { CONTEXT_LATEST_DESIGN_VERSION } from "../../types";
class BlockerBodyInlineStyles {
  static bodyContainer(_ref) {
    let {
      layout: {
        type,
        dialogBorderRadius
      },
      design: {
        bg,
        borderWidth,
        borderColor
      },
      bodyDesign: {
        padding
      },
      customCss: {
        antiAdBlocker
      },
      designVersion = CONTEXT_LATEST_DESIGN_VERSION
    } = _ref;
    const showFooter = designVersion === 1;
    const style = {
      background: bg,
      padding: padding.map(p => `${p}px`).join(" "),
      borderRadius: !showFooter && type === "dialog" ? `0 0 ${dialogBorderRadius}px ${dialogBorderRadius}px` : undefined,
      lineHeight: 1.4,
      overflow: "auto" // See https://stackoverflow.com/a/27829422/5506547
    };

    if (type === "dialog" && borderWidth > 0) {
      style.borderLeft = `${borderWidth}px solid ${borderColor}`;
      style.borderRight = style.borderLeft;
      if (!showFooter) {
        style.borderBottom = style.borderLeft;
      }
    }
    return {
      className: antiAdBlocker === "y" ? undefined : `rcb-body-container`,
      style
    };
  }
  static body(_ref2) {
    let {
      customCss: {
        antiAdBlocker
      }
    } = _ref2;
    const style = {
      margin: "auto"
    };
    return {
      className: antiAdBlocker === "y" ? undefined : `rcb-body`,
      style
    };
  }
  static description(_ref3) {
    let {
      design: {
        fontSize
      },
      bodyDesign: {
        descriptionInheritFontSize,
        descriptionFontSize
      },
      individualLayout: {
        descriptionTextAlign
      },
      customCss: {
        antiAdBlocker
      }
    } = _ref3;
    return {
      className: antiAdBlocker === "y" ? undefined : `rcb-description`,
      style: {
        marginBottom: 10,
        fontSize: descriptionInheritFontSize ? +fontSize : +descriptionFontSize,
        textAlign: descriptionTextAlign
      }
    };
  }
}
export { BlockerBodyInlineStyles };