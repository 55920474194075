import _extends from "@babel/runtime/helpers/extends";
import { useCallback, useState } from "react";
import { useBlocker, AsciiSpinner, BlockerButtonsInlineStyles } from "../../..";
const BlockerButton = _ref => {
  let {
    inlineStyle,
    type,
    onClick,
    children,
    framed,
    busyOnClick
  } = _ref;
  if (type === "hide") {
    return null;
  }
  const [isBusy, setIsBusy] = useState(false);
  const handleOnClick = useCallback(e => {
    if (!isBusy) {
      busyOnClick && setIsBusy(true);
      onClick?.(e);
    }
  }, [onClick, isBusy, busyOnClick]);
  const [isHover, setIsHover] = useState(false);
  const banner = useBlocker();
  const interactionAttr = {
    onClick: handleOnClick,
    onMouseEnter: () => setIsHover(true),
    onMouseLeave: () => setIsHover(false)
  };
  return h("div", _extends({}, type === "button" ? interactionAttr : {}, BlockerButtonsInlineStyles[inlineStyle](banner, isHover, framed)), h("span", type === "link" ? {
    ...interactionAttr,
    style: {
      cursor: "pointer"
    }
  } : {}, isBusy ? h(AsciiSpinner, null) : children));
};
export { BlockerButton };