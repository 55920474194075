import { BlockerTextContainer, BlockerHeroContainer, useBlocker } from "../..";
import { useEffect } from "react";
const Blocker = () => {
  const blocker = useBlocker();
  const {
    recorder,
    blocker: {
      visualType,
      visualThumbnail
    }
  } = blocker;

  // Automatically start recorder if one exists
  useEffect(() => {
    if (recorder) {
      recorder.restart();
      return () => {
        recorder.toggle(false);
      };
    }
    return () => {
      // Silence is golden.
    };
  }, []);
  if (process.env.PLUGIN_CTX === "pro") {
    /* onlypro:start */
    return visualType === "hero" && visualThumbnail ? h(BlockerHeroContainer, null) : h(BlockerTextContainer, null);
    /* onlypro:end */
  } else {
    return h(BlockerTextContainer, null);
  }
};
export { Blocker };