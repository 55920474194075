class BlockerInlineStyles {
  static topSide(_ref) {
    let {
      customCss: {
        antiAdBlocker
      }
    } = _ref;
    const style = {
      marginBottom: 15
    };
    return {
      className: antiAdBlocker === "y" ? undefined : `rcb-tb-top`,
      style
    };
  }
  static bottomSide(_ref2) {
    let {
      design: {
        bg
      },
      customCss: {
        antiAdBlocker
      }
    } = _ref2;
    const style = {
      background: bg
    };
    return {
      className: antiAdBlocker === "y" ? undefined : `rcb-tb-bottom`,
      style
    };
  }
}
export { BlockerInlineStyles };