import { commonInlineStyleHeaderHeadline, useBlocker, BlockerHeaderInlineStyles } from "../../..";
const BlockerHeader = _ref => {
  let {
    closeIcon
  } = _ref;
  const blocker = useBlocker();
  const {
    blocker: {
      name
    },
    texts: {
      blockerHeadline
    }
  } = blocker;
  return h("div", BlockerHeaderInlineStyles.headerContainer(blocker), h("div", BlockerHeaderInlineStyles.header(blocker), h("div", commonInlineStyleHeaderHeadline(blocker, !!closeIcon), blockerHeadline.replace(/{{name}}/gi, name)), closeIcon));
};
export { BlockerHeader };