import { ensureScriptWrapper, HTML_ATTRIBUTE_CAPTURE_PREFIX, HTML_ATTRIBUTE_CAPTURE_CLICK_PREFIX, HTML_ATTRIBUTE_CAPTURE_SUFFIX, HTML_ATTRIBUTE_BLOCKER_CONNECTED } from ".";
import { OPT_IN_CONTENT_BLOCKER_ALL } from "../events";
import { dispatchResizeEvent } from "../utils";
import { findVisualParent } from "../visual";
let tagTransformationCounter = 0;
const HTML_TAG_TRANSFORMATION_COUNTER = "consent-tag-transformation-counter";

/**
 * Transform e. g. `consent-original-href` to `href` of a given node.
 */
function transformToOriginalAttribute(_ref) {
  let {
    node,
    allowClickOverrides,
    onlyModifyAttributes,
    setVisualParentIfClassOfParent,
    overwriteAttributeValue
  } = _ref;
  return new Promise(resolve => {
    let performedClick = false;

    // Scripts need to be handled differently cause we want to use `postscribe` and should not be attached
    // to the DOM -> not be executed
    const tagName = node.tagName.toLowerCase();
    const isScript = tagName === "script";
    let workWithNode = isScript && !onlyModifyAttributes ? node.cloneNode(true) : node;

    // Do the transformation from "blocked" state to "unblocked"
    for (const attribute of workWithNode.getAttributeNames()) {
      if (attribute.startsWith(HTML_ATTRIBUTE_CAPTURE_PREFIX) && attribute.endsWith(HTML_ATTRIBUTE_CAPTURE_SUFFIX)) {
        let originalAttribute = attribute.substr(HTML_ATTRIBUTE_CAPTURE_PREFIX.length + 1);
        originalAttribute = originalAttribute.slice(0, (HTML_ATTRIBUTE_CAPTURE_SUFFIX.length + 1) * -1);

        // Check if `allowClickOverride` is given and then the `consent-click-original` attribute may be used, if given
        const overwriteClickAttribute = `${HTML_ATTRIBUTE_CAPTURE_CLICK_PREFIX}-${originalAttribute}-${HTML_ATTRIBUTE_CAPTURE_SUFFIX}`;
        const hasOverwriteClickAttribute = workWithNode.hasAttribute(overwriteClickAttribute) && allowClickOverrides;
        let value = workWithNode.getAttribute(hasOverwriteClickAttribute ? overwriteClickAttribute : attribute);
        if (hasOverwriteClickAttribute) {
          performedClick = true;
        }
        if (overwriteAttributeValue) {
          value = overwriteAttributeValue(value, originalAttribute);
        }

        // Finally, set the valid values
        workWithNode.setAttribute(originalAttribute, value);
        workWithNode.removeAttribute(attribute);
        workWithNode.removeAttribute(overwriteClickAttribute);

        // Automatically delegate a `click` event
        if (allowClickOverrides && ["a"].indexOf(tagName) > -1 && (["onclick"].indexOf(originalAttribute.toLowerCase()) > -1 || workWithNode.getAttribute("href")?.startsWith("#"))) {
          workWithNode.addEventListener(OPT_IN_CONTENT_BLOCKER_ALL, async _ref2 => {
            let {
              detail: {
                unblockedNodes
              }
            } = _ref2;
            return unblockedNodes.forEach(() => {
              workWithNode.click();
              dispatchResizeEvent(workWithNode);
            });
          });
        }
      }
    }

    // Are there any non-transformed clicked attributes? E.g. only add an attribute when it got clicked (without original attribute)
    for (const attribute of workWithNode.getAttributeNames()) {
      if (attribute.startsWith(HTML_ATTRIBUTE_CAPTURE_CLICK_PREFIX) && attribute.endsWith(HTML_ATTRIBUTE_CAPTURE_SUFFIX)) {
        const value = workWithNode.getAttribute(attribute);
        let originalAttribute = attribute.substr(HTML_ATTRIBUTE_CAPTURE_CLICK_PREFIX.length + 1);
        originalAttribute = originalAttribute.slice(0, (HTML_ATTRIBUTE_CAPTURE_SUFFIX.length + 1) * -1);

        // Finally, set the valid values
        if (allowClickOverrides) {
          workWithNode.setAttribute(originalAttribute, value);
          performedClick = true;
        }
        workWithNode.removeAttribute(attribute);
      }
    }
    const result = {
      performedClick,
      workWithNode: node
    };
    if (onlyModifyAttributes) {
      result.performedClick = false;
      resolve(result);
      return;
    }

    // Allow transition of custom element tags (web components).
    // See also https://html.spec.whatwg.org/multipage/custom-elements.html#custom-elements-customized-builtin-example)
    if (tagName.startsWith("consent-") && customElements) {
      const originalTag = tagName.substring(8);
      workWithNode.outerHTML = workWithNode.outerHTML.replace(/^<consent-[^\s]+/m, `<${originalTag} ${HTML_TAG_TRANSFORMATION_COUNTER}="${tagTransformationCounter}"`).replace(/<\/consent-[^\s]+>$/m, `</${originalTag}>`);
      workWithNode = document.querySelector(`[${HTML_TAG_TRANSFORMATION_COUNTER}="${tagTransformationCounter}"]`);
      tagTransformationCounter++;
      result.workWithNode = workWithNode;
    }
    workWithNode.style.removeProperty("display");

    // Also make the parent visual visible again
    const [visualParent] = findVisualParent(node, setVisualParentIfClassOfParent || {});
    if (visualParent !== node || visualParent?.hasAttribute(HTML_ATTRIBUTE_BLOCKER_CONNECTED)) {
      visualParent.style.removeProperty("display");
    }
    if (!isScript) {
      resolve(result);
    } else {
      const {
        outerHTML
      } = workWithNode;
      ensureScriptWrapper(outerHTML, node).then(() => resolve(result));
    }
  });
}
export { transformToOriginalAttribute };