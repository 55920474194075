class BlockerFooterInlineStyles {
  static footerContainer(_ref) {
    let {
      layout: {
        type,
        dialogBorderRadius
      },
      design,
      footerDesign: {
        inheritBg,
        bg,
        inheritTextAlign,
        textAlign,
        padding,
        fontSize,
        fontColor
      },
      customCss: {
        antiAdBlocker
      }
    } = _ref;
    const style = {
      padding: padding.map(p => `${p}px`).join(" "),
      background: inheritBg ? design.bg : bg,
      borderRadius: type === "dialog" ? `0 0 ${dialogBorderRadius}px ${dialogBorderRadius}px` : undefined,
      fontSize: +fontSize,
      color: fontColor,
      textAlign: inheritTextAlign ? design.textAlign : textAlign
    };
    if (type === "dialog" && design.borderWidth > 0) {
      style.borderBottom = `${design.borderWidth}px solid ${design.borderColor}`;
      style.borderLeft = style.borderBottom;
      style.borderRight = style.borderBottom;
    }
    return {
      className: antiAdBlocker === "y" ? undefined : `rcb-footer-container`,
      style
    };
  }
  static footer(_ref2) {
    let {
      customCss: {
        antiAdBlocker
      }
    } = _ref2;
    const style = {
      margin: "auto",
      lineHeight: 1.8
    };
    return {
      className: antiAdBlocker === "y" ? undefined : `rcb-footer`,
      style
    };
  }
  static footerSeparator(_ref3) {
    let {
      layout: {
        type
      },
      design,
      footerDesign: {
        borderWidth,
        borderColor
      },
      customCss: {
        antiAdBlocker
      }
    } = _ref3;
    const style = {
      height: +borderWidth,
      background: borderColor
    };
    if (type === "dialog" && design.borderWidth > 0) {
      style.borderLeft = `${design.borderWidth}px solid ${design.borderColor}`;
      style.borderRight = style.borderLeft;
    }
    return {
      className: antiAdBlocker === "y" ? undefined : `rcb-footer-separator`,
      style
    };
  }
}
export { BlockerFooterInlineStyles };