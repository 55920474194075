class BlockerContentInlineStyles {
  static inner(_ref) {
    let {
      layout: {
        type,
        dialogBorderRadius
      },
      design: {
        borderWidth,
        borderColor,
        textAlign,
        fontColor,
        fontInheritFamily,
        fontFamily
      },
      customCss: {
        antiAdBlocker
      },
      blocker: {
        visualType
      }
    } = _ref;
    const hasBg = visualType === "wrapped";
    const style = {
      textAlign,
      fontFamily: fontInheritFamily ? undefined : fontFamily,
      color: fontColor,
      // margin: "10px 0",
      borderRadius: type === "dialog" ? +dialogBorderRadius : undefined,
      border: type === "banner" ? borderWidth > 0 ? `${borderWidth}px solid ${borderColor}` : undefined : undefined,
      position: "relative",
      padding: hasBg ? "30px 50px" : undefined,
      overflow: hasBg ? "hidden" : undefined
    };
    return {
      className: `wp-exclude-emoji ${antiAdBlocker === "y" ? "" : `rcb-inner`}`,
      style
    };
  }
  static innerBackground(_ref2) {
    let {
      customCss: {
        antiAdBlocker
      },
      blocker: {
        visualType,
        visualThumbnail,
        visualBlur
      }
    } = _ref2;
    const hasBg = visualType === "wrapped";
    const style = {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: hasBg ? "block" : "none",
      filter: visualBlur > 0 ? `blur(${visualBlur}px)` : undefined
    };
    if (hasBg) {
      style.background = `url('${visualThumbnail.url}') no-repeat center center`;
      style.backgroundSize = "cover";
    }
    return {
      className: antiAdBlocker === "y" ? "" : `rcb-inner-bg`,
      style
    };
  }
  static content(_ref3) {
    let {
      blocker: {
        visualType
      },
      customCss: {
        antiAdBlocker
      }
    } = _ref3;
    return {
      className: antiAdBlocker === "y" ? undefined : `rcb-content`,
      style: {
        boxShadow: visualType === "wrapped" ? "rgb(0 0 0 / 35%) 0px 0px 0px 7px, #0000004d 0px 0px 100px 30px" : undefined,
        position: "relative"
      }
    };
  }
}
export { BlockerContentInlineStyles };