class BlockerHeaderInlineStyles {
  static headerContainer(_ref) {
    let {
      layout: {
        type,
        dialogBorderRadius
      },
      design: {
        borderWidth,
        borderColor,
        ...restDesign
      },
      headerDesign: {
        inheritBg,
        bg,
        padding
      },
      customCss: {
        antiAdBlocker
      }
    } = _ref;
    const style = {
      padding: padding.map(p => `${p}px`).join(" "),
      background: inheritBg ? restDesign.bg : bg,
      borderRadius: type === "dialog" ? `${dialogBorderRadius}px ${dialogBorderRadius}px 0 0` : undefined
    };
    if (type === "dialog" && borderWidth > 0) {
      style.borderTop = `${borderWidth}px solid ${borderColor}`;
      style.borderLeft = style.borderTop;
      style.borderRight = style.borderTop;
    }
    return {
      className: antiAdBlocker === "y" ? undefined : `rcb-header-container`,
      style
    };
  }
  static header(_ref2) {
    let {
      design: {
        textAlign
      },
      headerDesign: {
        inheritTextAlign,
        ...restHeaderDesign
      },
      customCss: {
        antiAdBlocker
      }
    } = _ref2;
    const useTextAlign = inheritTextAlign ? textAlign : restHeaderDesign.textAlign;
    return {
      className: antiAdBlocker === "y" ? undefined : `rcb-header`,
      style: {
        margin: "auto",
        display: "flex",
        justifyContent: useTextAlign === "center" ? "center" : useTextAlign === "right" ? "flex-end" : undefined,
        alignItems: "center",
        position: "relative"
      }
    };
  }
  static headerSeparator(_ref3) {
    let {
      layout: {
        type
      },
      design,
      headerDesign: {
        borderWidth,
        borderColor
      },
      customCss: {
        antiAdBlocker
      }
    } = _ref3;
    const style = {
      height: +borderWidth,
      background: borderColor
    };
    if (type === "dialog" && design.borderWidth > 0) {
      style.borderLeft = `${design.borderWidth}px solid ${design.borderColor}`;
      style.borderRight = style.borderLeft;
    }
    return {
      className: antiAdBlocker === "y" ? undefined : `rcb-header-separator`,
      style
    };
  }
}
export { BlockerHeaderInlineStyles };